var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"mt-2"},[_c('v-col',{attrs:{"md":"4"}},[_c('h1',{staticClass:"mt-2 mb-6"},[_vm._v("Contributi")])]),_c('v-col',{attrs:{"md":"8"}})],1),_c('v-row',{staticClass:"mt-5"},[_c('v-col',{attrs:{"md":"6"}},[_c('v-autocomplete',{attrs:{"items":_vm.campagneAttive,"label":"Campagna","item-text":"Descrizione","item-value":"Idfile","filled":_vm.tce.filled,"outlined":_vm.tce.outlined,"solo":_vm.tce.solo,"dense":_vm.tce.dense},model:{value:(_vm.campagnaCorrente),callback:function ($$v) {_vm.campagnaCorrente=$$v},expression:"campagnaCorrente"}})],1),(!_vm.isAdmin)?_c('v-col',{attrs:{"md":"6"}},[_c('v-text-field',{attrs:{"readonly":"","label":"Organizzato","value":_vm.currentUser.Nome,"filled":_vm.tce.filled,"outlined":_vm.tce.outlined,"solo":_vm.tce.solo,"dense":_vm.tce.dense}})],1):_vm._e(),(_vm.isAdmin)?_c('v-col',{attrs:{"md":"6"}},[_c('div',{staticClass:"d-block d-md-flex align-stretch"},[_c('v-autocomplete',{attrs:{"disabled":_vm.organizzatiTutti,"multiple":"","items":_vm.listaOrganizzati,"label":"Organizzati","item-text":"DescOrganizzato2","item-value":"CodOrganizzato","filled":_vm.tce.filled,"outlined":_vm.tce.outlined,"solo":_vm.tce.solo,"dense":_vm.tce.dense},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index < 2)?_c('span',{staticClass:"mr-2"},[_vm._v(_vm._s(item.RagSoc)+",")]):_vm._e(),(index === 2)?_c('span',{staticClass:"grey--text caption"},[_vm._v("(+ altri "+_vm._s(_vm.organizzatiCorrenti.length - 2)+")")]):_vm._e()]}}],null,false,2972088679),model:{value:(_vm.organizzatiCorrenti),callback:function ($$v) {_vm.organizzatiCorrenti=$$v},expression:"organizzatiCorrenti"}}),_c('v-switch',{staticClass:"ml-0 ml-md-5 mt-md-3",attrs:{"inset":"","label":"Tutti gli organizzati"},on:{"change":_vm.changeOrganizzatiTutti},model:{value:(_vm.organizzatiTutti),callback:function ($$v) {_vm.organizzatiTutti=$$v},expression:"organizzatiTutti"}})],1)]):_vm._e()],1),_c('v-row',{staticClass:"mt-5"},[_c('v-col',{attrs:{"md":"6"}},[_c('v-tooltip',{attrs:{"top":"","open-delay":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('div',{staticClass:"d-block d-md-flex align-stretch"},[_c('v-autocomplete',{attrs:{"items":_vm.listaLotti,"disabled":!_vm.campagnaCorrente || _vm.lottiTutti,"label":"Lotto","item-text":"Descrizione","item-value":"Lotto","filled":_vm.tce.filled,"outlined":_vm.tce.outlined,"solo":_vm.tce.solo,"dense":_vm.tce.dense},model:{value:(_vm.lottoCorrente),callback:function ($$v) {_vm.lottoCorrente=$$v},expression:"lottoCorrente"}}),_c('v-switch',{staticClass:"ml-0 ml-md-5 mt-md-3",attrs:{"inset":"","disabled":!_vm.campagnaCorrente,"label":"Tutti i lotti"},on:{"change":_vm.changeLottiTutti},model:{value:(_vm.lottiTutti),callback:function ($$v) {_vm.lottiTutti=$$v},expression:"lottiTutti"}})],1)])]}}])},[_c('span',[_vm._v("Tranche di pagamento dei contributi.")])])],1),_c('v-col',{attrs:{"md":"6"}},[_c('v-tooltip',{attrs:{"top":"","open-delay":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-select',{attrs:{"label":"Tipo report","items":_vm.tipiReport,"item-text":"value","item-value":"id","filled":_vm.tce.filled,"outlined":_vm.tce.outlined,"solo":_vm.tce.solo,"dense":_vm.tce.dense},model:{value:(_vm.tipoReport),callback:function ($$v) {_vm.tipoReport=$$v},expression:"tipoReport"}})],1)]}}])},[_c('ul',[_c('li',[_c('strong',[_vm._v("Aggregato")]),_vm._v(": Elenco delle quantità e del contributo della Campagna."),_c('br')]),_c('li',[_c('strong',[_vm._v("Esploso")]),_vm._v(": elenco analitico dei PDO interessati alla Campagna."),_c('br'),_vm._v("Cliccare su dettagli per visualizzare i particolari dei PDO (telaio, n. doc, data…).")]),(_vm.isAdmin)?_c('li',[_c('strong',[_vm._v("Fatturato")])]):_vm._e()])])],1)],1),_c('v-row',[_c('v-col',{staticClass:"text-right"},[_c('v-btn',{attrs:{"color":"primary","outlined":"","x-large":"","disabled":!_vm.reportGenerabile,"loading":_vm.loading},on:{"click":_vm.generaReport}},[_vm._v("cerca")])],1)],1),_c('v-row',[_c('v-col',[_c('v-data-table',{staticClass:"reportTable elevation-1",attrs:{"item-class":"caption","headers":_vm.tipoReport==3 ? _vm.tableHeadersFatturato : _vm.tableHeaders,"items":_vm.reportFiltrato,"search":_vm.tableSearch,"loading":_vm.loading,"loading-text":"Caricamento...","item-key":"codorganizzato","single-expand":"","expanded":_vm.tableExpanded,"show-expand":_vm.tipoReport==1},on:{"update:expanded":function($event){_vm.tableExpanded=$event},"item-expanded":_vm.rowExpanded},scopedSlots:_vm._u([{key:"item.contributo",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(_vm._f("toCurrency")(item.contributo)))])]}},{key:"item.manodopera",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(_vm._f("toCurrency")(item.manodopera)))])]}},{key:"item.totalecontributo",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(_vm._f("toCurrency")(item.totalecontributo)))])]}},{key:"item.fatturato_netto",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(_vm._f("toCurrency")(item.fatturato_netto)))])]}},{key:"item.fatturato_lordo",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(_vm._f("toCurrency")(item.fatturato_lordo)))])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-data-table',{staticClass:"reportTableDetail mt-3 mb-3",attrs:{"dense":"","item-class":"ma-0","headers":_vm.tableHeadersDetail,"items":_vm.reportDetail,"search":_vm.tableSearchDetail,"loading":_vm.reportDetail.length==0,"loading-text":"Caricamento..."},scopedSlots:_vm._u([{key:"item.datadoc",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(_vm._f("reportShortDate")(item.datadoc)))])]}},{key:"item.contributo",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(_vm._f("toCurrency")(item.contributo)))])]}},{key:"item.manodopera",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(_vm._f("toCurrency")(item.manodopera)))])]}},{key:"item.totalecontributo",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(_vm._f("toCurrency")(item.totalecontributo)))])]}},{key:"item.totfatturalordo",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(_vm._f("toCurrency")(item.totfatturalordo)))])]}},{key:"item.totfatturanetto",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(_vm._f("toCurrency")(item.totfatturanetto)))])]}}],null,true)})],1)]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',[(_vm.tipoReport===0)?_c('div',[_c('v-btn',{attrs:{"color":"grey","text":"","small":"","outlined":"","loading":_vm.loadingEsploso,"disabled":_vm.loadingEsploso},on:{"click":_vm.generaReportXlsx}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-microsoft-excel")]),_vm._v(" genera e scarica report aggregato")],1)],1):_vm._e(),(_vm.tipoReport===1)?_c('div',[_c('v-row',[_c('v-col',{attrs:{"lg":"6","md":"12"}},[_c('v-alert',{attrs:{"type":"warning","outlined":"","dense":""}},[_vm._v("Attenzione: la generazione del report esploso può richiedere molto tempo."),_c('br'),_vm._v("Si consiglia di filtrare per singolo organizzato.")])],1)],1),_c('v-btn',{attrs:{"color":"grey","text":"","small":"","outlined":"","loading":_vm.loadingEsploso,"disabled":_vm.loadingEsploso},on:{"click":_vm.generaReportEsplosoXlsx}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-microsoft-excel")]),_vm._v(" genera e scarica report esploso")],1)],1):_vm._e(),(_vm.tipoReport===3)?_c('v-btn',{attrs:{"color":"grey","text":"","small":"","outlined":"","loading":_vm.loadingEsploso,"disabled":_vm.loadingEsploso},on:{"click":_vm.generaReportFatturatoXlsx}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-microsoft-excel")]),_vm._v(" genera e scarica report fatturato")],1):_vm._e()],1)],1),_c('debug-panel',{staticClass:"mt-6",attrs:{"label":"reportRequest"}},[_vm._v(" "+_vm._s(_vm.reportRequest)+" ")]),_c('debug-panel',{staticClass:"mt-6",attrs:{"label":"report"}},[_vm._v(" "+_vm._s(_vm.report)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }