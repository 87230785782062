<template>
	<standard-container menu-id="contributi">
		<!-- header -->
		<template v-slot:header>
			<v-tabs v-model="currentTab" :show-arrows="true" background-color="transparent">
				<!-- <v-tab v-if="$isAuthorizedTo('contributi.contributi')" key="tabContributi">Campagne</v-tab> -->
				<v-tab v-if="$isAuthorizedTo('contributi.compensierogati')" key="tabCompensiErogati">Contributi</v-tab>
			</v-tabs>		
		</template>

		<!-- body slot -->
		<v-tabs-items v-model="currentTab">
			<!--campagne contributi -->
			<!-- 
			<v-tab-item v-if="$isAuthorizedTo('contributi.contributi')" key="tabContributi" eager class="ma-1">
				<contributi-contributi></contributi-contributi>
			</v-tab-item>
			-->
			<!-- Contributi -->
			<v-tab-item v-if="$isAuthorizedTo('contributi.compensierogati')" key="tabCompensiErogati" eager class="ma-1">
				<contributi-compensierogati></contributi-compensierogati>
			</v-tab-item>
		</v-tabs-items>
	</standard-container>
</template>

<script>
/**********************************************************************************************
**** Contributi ***********************************************************************************
***********************************************************************************************
* 
*/
import StandardContainer from '@/components/standard-container'
// import ContributiContributi from '@/views/Contributi/contributi-contributi'
import ContributiCompensierogati from '@/views/Contributi/contributi-compensierogati'
export default {
	name: 'ReportPage',
	components: { 
		StandardContainer, ContributiCompensierogati, }, //ContributiContributi, },
	data: () => {
		return {
			currentTab: -1,
		}
	},
	computed: {
		viewDebugElements() { return this.$store.getters['viewDebugElements'] },
	},
	mounted() {
	},
	methods: {

	},
}
</script>

<style scoped lang="less">

</style>