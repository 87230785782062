<template>
	<div>
		<v-row class="mt-2">
			<v-col md="4">
				<h1 class="mt-2 mb-6">Contributi</h1>
			</v-col>
			<v-col md="8">
			</v-col>
		</v-row>
		<v-row class="mt-5">
			<v-col md="6">
				<v-autocomplete v-model="campagnaCorrente" :items="campagneAttive" label="Campagna" item-text="Descrizione" item-value="Idfile" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense"></v-autocomplete>
			</v-col>
			<v-col v-if="!isAdmin" md="6">
				<v-text-field readonly label="Organizzato" :value="currentUser.Nome" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense"></v-text-field>
			</v-col>
			<v-col v-if="isAdmin" md="6">
				<div class="d-block d-md-flex align-stretch">
					<v-autocomplete v-model="organizzatiCorrenti" :disabled="organizzatiTutti" multiple :items="listaOrganizzati" label="Organizzati" item-text="DescOrganizzato2" item-value="CodOrganizzato" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense">
						<template v-slot:selection="{ item, index }">
							<span v-if="index < 2" class="mr-2">{{ item.RagSoc }},</span>
							<span v-if="index === 2" class="grey--text caption">(+ altri {{ organizzatiCorrenti.length - 2 }})</span>
						</template>					
					</v-autocomplete>
					<v-switch v-model="organizzatiTutti" inset class="ml-0 ml-md-5 mt-md-3" label="Tutti gli organizzati" @change="changeOrganizzatiTutti"></v-switch>
				</div>
			</v-col>
		</v-row>
		<v-row class="mt-5">
			<v-col md="6">
				<v-tooltip top open-delay="500">
					<template v-slot:activator="{ on, attrs }">
						<div v-bind="attrs" v-on="on">
							<div class="d-block d-md-flex align-stretch">
								<v-autocomplete v-model="lottoCorrente" :items="listaLotti" :disabled="!campagnaCorrente || lottiTutti" label="Lotto" item-text="Descrizione" item-value="Lotto" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense"></v-autocomplete>
								<v-switch v-model="lottiTutti" inset class="ml-0 ml-md-5 mt-md-3" :disabled="!campagnaCorrente" label="Tutti i lotti" @change="changeLottiTutti"></v-switch>
							</div>
						</div>
					</template>
					<span>Tranche di pagamento dei contributi.</span>
				</v-tooltip>				
			</v-col>
			<v-col md="6">
				<v-tooltip top open-delay="500">
					<template v-slot:activator="{ on, attrs }">
						<div v-bind="attrs" v-on="on">
							<v-select v-model="tipoReport" label="Tipo report" :items="tipiReport" item-text="value" item-value="id" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense"></v-select>
						</div>
					</template> 
					<ul>
						<li><strong>Aggregato</strong>: Elenco delle quantità e del contributo della Campagna.<br></li>
						<li><strong>Esploso</strong>: elenco analitico dei PDO interessati alla Campagna.<br>Cliccare su dettagli per visualizzare i particolari dei PDO (telaio, n. doc, data…).</li>
						<li v-if="isAdmin"><strong>Fatturato</strong></li>
					</ul>
				</v-tooltip>
				<!--
				<div v-if="tipoReport == 1">
					<label>Seleziona anno e mese (o mesi) per il report esploso</label><br>
					<v-date-picker v-model="mesiContributi" multiple type="month" :min="minimoAnnoContributi"></v-date-picker>
				</div>
				-->
			</v-col>
		</v-row>
		<v-row>
			<v-col class="text-right">
				<v-btn color="primary" outlined x-large :disabled="!reportGenerabile" :loading="loading" @click="generaReport">cerca</v-btn>
			</v-col>
		</v-row>
		<v-row>
			<v-col>
				<v-data-table 
					
					class="reportTable elevation-1"
					item-class="caption"
					:headers="tipoReport==3 ? tableHeadersFatturato : tableHeaders"
					:items="reportFiltrato"
					:search="tableSearch"
					:loading="loading"
					loading-text="Caricamento..."
					item-key="codorganizzato"
					single-expand
					:expanded.sync="tableExpanded"
					:show-expand="tipoReport==1"
					@item-expanded="rowExpanded"
				>
					<template v-slot:[`item.contributo`]="{ item }">
						<span class="text-no-wrap">{{ item.contributo | toCurrency }}</span>
					</template>
					<template v-slot:[`item.manodopera`]="{ item }">
						<span class="text-no-wrap">{{ item.manodopera | toCurrency }}</span>
					</template>
					<template v-slot:[`item.totalecontributo`]="{ item }">
						<span class="text-no-wrap">{{ item.totalecontributo | toCurrency }}</span>
					</template>
					<template v-slot:[`item.fatturato_netto`]="{ item }">
						<span class="text-no-wrap">{{ item.fatturato_netto | toCurrency }}</span>
					</template>
					<template v-slot:[`item.fatturato_lordo`]="{ item }">
						<span class="text-no-wrap">{{ item.fatturato_lordo | toCurrency }}</span>
					</template>
					<!-- eslint-disable-next-line vue/no-unused-vars -->
					<template v-slot:expanded-item="{ headers }">
						<td :colspan="headers.length">
							<v-data-table 
								dense
								class="reportTableDetail mt-3 mb-3"
								item-class="ma-0"
								
								:headers="tableHeadersDetail"
								:items="reportDetail"
								:search="tableSearchDetail"
								:loading="reportDetail.length==0"
								loading-text="Caricamento..."
							>
								<template v-slot:[`item.datadoc`]="{ item }">
									<span class="text-no-wrap">{{ item.datadoc | reportShortDate }}</span>
								</template>
								<template v-slot:[`item.contributo`]="{ item }">
									<span class="text-no-wrap">{{ item.contributo | toCurrency }}</span>
								</template>															
								<template v-slot:[`item.manodopera`]="{ item }">
									<span class="text-no-wrap">{{ item.manodopera | toCurrency }}</span>
								</template>															
								<template v-slot:[`item.totalecontributo`]="{ item }">
									<span class="text-no-wrap">{{ item.totalecontributo | toCurrency }}</span>
								</template>															
								<template v-slot:[`item.totfatturalordo`]="{ item }">
									<span class="text-no-wrap">{{ item.totfatturalordo | toCurrency }}</span>
								</template>															
								<template v-slot:[`item.totfatturanetto`]="{ item }">
									<span class="text-no-wrap">{{ item.totfatturanetto | toCurrency }}</span>
								</template>															
							</v-data-table>
						</td>
					</template>					
				</v-data-table>
			</v-col>
		</v-row>
		<v-row>
			<v-col>
				<div v-if="tipoReport===0">
					<v-btn color="grey" text small outlined :loading="loadingEsploso" :disabled="loadingEsploso" @click="generaReportXlsx"><v-icon left>mdi-microsoft-excel</v-icon> genera e scarica report aggregato</v-btn>
				</div>
				<div v-if="tipoReport===1">
					<v-row>
						<v-col lg="6" md="12"><v-alert type="warning" outlined dense>Attenzione: la generazione del report esploso può richiedere molto tempo.<br>Si consiglia di filtrare per singolo organizzato.</v-alert></v-col>
					</v-row>
					<!-- <v-btn color="grey" text small outlined :loading="loadingEsploso" :disabled="loadingEsploso" @click="generaReportEsplosoHtml"><v-icon left>mdi-microsoft-excel</v-icon> genera e scarica report esploso [OLD]</v-btn> -->
					<v-btn color="grey" text small outlined :loading="loadingEsploso" :disabled="loadingEsploso" @click="generaReportEsplosoXlsx"><v-icon left>mdi-microsoft-excel</v-icon> genera e scarica report esploso</v-btn>
				</div>
				<v-btn v-if="tipoReport===3" color="grey" text small outlined :loading="loadingEsploso" :disabled="loadingEsploso" @click="generaReportFatturatoXlsx"><v-icon left>mdi-microsoft-excel</v-icon> genera e scarica report fatturato</v-btn>
			</v-col>
		</v-row>

		<debug-panel label="reportRequest" class="mt-6">
			{{ reportRequest }}
		</debug-panel>
		<debug-panel label="report" class="mt-6">
			{{ report }}
		</debug-panel>
	</div>
</template>

<script>
/**********************************************************************************************
**** CARD VERIFICA ****************************************************************************
***********************************************************************************************
* 
*/
import DebugPanel from '@/components/debug-panel'
// import AutocompleteSelectall from '@/components/autocomplete-selectall'
/* NOTE: ho fatto il componente select all ma è a abbastanza lento perché gli organizzati sono tanti, 
		 non ha senso usarlo perché se aumentano ancora rischia di rallentare troppo. 
		 Il meccanismo con il flag a parte inoltre consente di non inviare liste inutilmente lunghe al server!
		 Anche per i lotti è inutile perché li la selezione è o uno o tutti. 
	todo: valutare la scrittura di una variante dove il pulsante "all", pur essendo integrato nel primo slot, agisca esattamente come fosse esterno (usare una prop in sync per restituirne il valore)
*/
//
export default {
	name: 'ReportContributi',
	components: { DebugPanel },
	props: {

	},
	data: () => {
		return {
			loading: false,
			loadingEsploso: false,
			campagneAttive: [],
			campagnaCorrente: '',
			listaOrganizzati: [],
			listaLotti: [],
			lottiTutti: true,
			lottoCorrente: '',
			organizzatiTutti: false, // se true usa tutti gli organizzati disponibili per il report
			organizzatiCorrenti: [], // lista organizzati selezionati (a meno che non sia selezionato organizzatiTutti) - in caso di utente non admin contiene solo il codice dell'utente corrente
			tipiReport: [{id:0, value:'Contributi Aggregato'}, {id:1, value:'Contributi Esploso'}],
			tipoReport: 0,
			anniContributi: [],
			mesiContributi: [],
			report: [],
			reportEsploso: [],
			reportDetail: [],
			tableExpanded: [],
			tableSearch: '',
			tableHeaders: [
				{ text: 'Cod.Organizzato' 		, value: 'codorganizzato' 	},
				{ text: 'Organizzato' 			, value: 'organizzato' 		},
				{ text: 'Quantità Prodotto' 	, value: 'quantita' 		, align: 'end'},
				{ text: 'Contributo' 			, value: 'contributo' 		, align: 'end'},
				{ text: 'Manodopera' 			, value: 'manodopera' 		, align: 'end'},
				{ text: 'Totale Contributo' 	, value: 'totalecontributo' , align: 'end'},
			],
			tableHeadersFatturato: [
				{ text: 'Cod.Organizzato' 	, value: 'codorganizzato' 	},
				{ text: 'Organizzato' 		, value: 'organizzato' 		},
				{ text: 'Contributo' 		, value: 'contributo' 		, align: 'end'},
				{ text: 'Quantità' 			, value: 'quantita' 		, align: 'end'},
				{ text: 'PDO' 				, value: 'PDO' 				, align: 'end'},
				{ text: 'Fatturato Netto' 	, value: 'fatturato_netto' 	, align: 'end'},
				{ text: 'Fatturato Lordo' 	, value: 'fatturato_lordo' 	, align: 'end'},
			],
			tableSearchDetail: '',
			tableHeadersDetail: [
				{ text: 'VIN' 				, value: 'vin' 					},			
				{ text: 'Data Documento'	, value: 'datadoc' 				},
				{ text: 'Numero'	, value: 'numdoc' 				},
				{ text: 'Famiglia' 			, value: 'famiglia' 			},
				{ text: 'Particolare' 		, value: 'particolare' 			},
				{ text: 'Quantità'	, value: 'qta' 					, align: 'end'},
				{ text: 'Contributo' 		, value: 'contributo' 			, align: 'end'},
				{ text: 'Manodopera' 		, value: 'manodopera' 			, align: 'end'},
				{ text: 'P.D.L' 			, value: 'flagposlav' 			, align: 'center'},
				{ text: 'Sconto' 			, value: 'flagappsconto' 		, align: 'center'},
				// { text: 'Skoda Super Card'	, value: 'flagskodasupercard'	, align: 'center'},
				// { text: 'Tot Contributo' 	, value: 'totalecontributo' 	, align: 'end'},
				// { text: 'totfatturalordo' 	, value: 'totfatturalordo' 		, align: 'end'},
				// { text: 'totfatturanetto' 	, value: 'totfatturanetto' 		, align: 'end'},
			],
			errors: [],
		}
	},
	computed: {
		appSetup() { return window.setup },
		viewDebugElements() { return this.$store.getters['viewDebugElements'] },
		setup() { return this.$store.getters['setup'] },
		tce() { return this.$store.getters['tipologiaCampiEdit'] },
		compactVisualization() { return this.$store.getters['compactVisualization'] },	
		currentUser() { return this.$store.getters['currentUser'] },
		cellularOpenMode() { return this.$store.getters['cellularOpenMode'] },
		isAdmin() { return this.currentUser.IsAdmin || this.currentUser.IsSuperuser},
		minimoAnnoContributi() {
			return this.anniContributi && this.anniContributi.length>0 ? this.anniContributi[0]+ '-01' : '2010-01'
		},
		// true se tutti i parametri necessari per il report sono compilati
		reportGenerabile() { 
			// return !!this.campagnaCorrente && (!!this.lottoCorrente || this.lottiTutti) && ( this.organizzatiCorrenti.length>0 || this.organizzatiTutti ) && (this.tipoReport == 0 || this.tipoReport == 3 || (this.tipoReport == 1 && this.mesiContributi.length>0))
			return !!this.campagnaCorrente && (!!this.lottoCorrente || this.lottiTutti) && ( this.organizzatiCorrenti.length>0 || this.organizzatiTutti ) && this.tipoReport >= 0
		},
		// compone l'oggetto per la richiesta del report
		reportRequest() {
			return {
				campagna: this.campagnaCorrente,
				organizzati: this.organizzatiCorrenti,
				organizzatiAll: this.organizzatiTutti,
				lotto: this.lottoCorrente,
				lottiAll: this.lottiTutti,
				tipoReport: this.tipoReport,
				mesiEsploso: this.mesiContributi,
				valid: this.reportGenerabile,
			}
		},
		// ripulisce il report a video (non quello excel) dalle righe vuote
		reportFiltrato() {
			return this.report.filter(x => { return !!x.organizzato})
		},
	},
	watch:{
		// tiene traccia delle variazioni della campagna corrente
		campagnaCorrente(to, from) {
			// riscarica i lotti
			this.fetchLotti()
		},
		reportRequest(to, from) {
			// se varia un parametro del report sbianca eventuali risultati precedenti
			this.report = []
		}
	},	
	created: function () {
		this.fetchCampagneAttive()
		this.fetchAnni()
		this.fetchOrganizzati()
		if(this.isAdmin) {
			// abilita il tipo report Fatturato solo per gli utenti admin
			this.tipiReport.push( {id:3, value:'Fatturato'})
			this.organizzatiTutti = true
		} else {
			// per gli utenti non admin non carica la tendina degli organizzati e usa l'utente stesso
			// this.organizzatiCorrenti.push(this.currentUser.UserName)
		}
	},
	methods: {
		// carica le campagne attive
		fetchCampagneAttive() {
			this.loading = true
			this.$store.dispatch('genericApiPost', {apiUrl: '/report/campagneAttive'}).then(result => {
				this.campagneAttive = result
			}).catch(error => {
				this.errors.push({text: error, pre: true})
			}).finally(() => {
				this.loading = false
			})
		},
		// scarica gli anni filtrabili (ricerca esplosa)
		fetchAnni() {
			this.loading = true
			this.$store.dispatch('genericApiPost', {apiUrl: '/report/anniContributi'}).then(result => {
				this.anniContributi = result
			}).catch(error => {
				this.errors.push({text: error, pre: true})
			}).finally(() => {
				this.loading = false
			})
		},
		// carica gli organizzati (solo per i super e admin viene fornita una lista completa. Per gli organizzati viene restituito un solo valore )
		fetchOrganizzati() {
			this.organizzatiCorrenti = []
			this.loading = true
			this.$store.dispatch('genericApiPost', {apiUrl: '/report/organizzatiAttivi'}).then(result => {
				this.listaOrganizzati = result
				if(this.listaOrganizzati.length == 1) {
					this.organizzatiCorrenti.push(this.listaOrganizzati[0].CodOrganizzato)
				}
			}).catch(error => {
				this.errors.push({text: error, pre: true})
			}).finally(() => {
				this.loading = false
			})
		},
		// carica gli organizzati (solo per i super e admin viene fornita una lista completa. Per gli organizzati viene restituito un solo valore )
		fetchLotti() {
			this.loading = true
			this.$store.dispatch('genericApiPost', {apiUrl: '/report/lottiContributi', value: {Idfile: this.campagnaCorrente}}).then(result => {
				this.listaLotti = result
			}).catch(error => {
				this.errors.push({text: error, pre: true})
			}).finally(() => {
				this.loading = false
			})
		},
		// semplice pulizia estetica della tendina organizzati se viene selezionato il flag tutti
		changeOrganizzatiTutti() {
			if(this.organizzatiTutti) this.organizzatiCorrenti = []
		},
		// semplice pulizia estetica della tendina lotti se viene selezionato il flag tutti
		changeLottiTutti() {
			if(this.lottiTutti) this.lottoCorrente = ''
		},
		// genera il report con i parametri indicati
		generaReport() {
			this.report = []
			this.tableExpanded = []
			this.reportDetail = []
			this.loading = true
			this.$store.dispatch('genericApiPost', {apiUrl: '/report/caricaContributi', value: this.reportRequest}).then(result => {
				this.report = result
			}).catch(error => {
				this.errors.push({text: error, pre: true})
			}).finally(() => {
				this.loading = false
			})
		},
		// genera e scarica il report standard del fatturato in formato xlsx (precedentemente era in xls ma dava problemi di formattazione vedi -> #180)
		generaReportFatturatoXlsx() {
			this.loadingEsploso = true
			this.$store.dispatch('genericApiPost', {apiUrl: '/report/caricaContributiXlsx', value: this.reportRequest, options: {responseType: 'blob'}}).then(blob => {
				// crea il link farlocco per aprire il doc
				let link = document.createElement('a')
				document.body.appendChild(link) // fix per firefox
				link.setAttribute('type', 'hidden') // fix per firefox
				link.href = window.URL.createObjectURL(blob)
				link.target = '_blank'
				link.download = 'report-contributi-fatturato.xlsx' // imposta il nome del file
				link.click()
			}).catch(error => {
				this.errors.push({text: error, pre: true})
			}).finally(() => {
				this.loadingEsploso = false
			})
		},
		//
		rowExpanded(data) {
			if(data.value) {
				this.reportDetail = []
				var detailRequest = JSON.parse(JSON.stringify(this.reportRequest))
				detailRequest.detailCodOrganizzato = data.item.codorganizzato
				// this.loading = true
				this.$store.dispatch('genericApiPost', {apiUrl: '/report/caricaContributiDetail', value: detailRequest}).then(result => {
					this.reportDetail = result
				}).catch(error => {
					this.errors.push({text: error, pre: true})
				}).finally(() => {
					// this.loading = false
				})			
			} else {
				this.reportDetail = []
			}
		},
		// genera il report (aggregato) in formato xlsx
		generaReportXlsx() {
			this.reportEsploso = []
			this.loadingEsploso = true
			this.$store.dispatch('genericApiPost', {apiUrl: '/report/caricaContributiAggregatiXlsx', value: this.reportRequest, options: {responseType: 'blob'}}).then(blob => {
				// crea il link farlocco per aprire il doc
				let link = document.createElement('a')
				document.body.appendChild(link) // fix per firefox
				link.setAttribute('type', 'hidden') // fix per firefox
				link.href = window.URL.createObjectURL(blob)
				link.target = '_blank'
				link.download = 'report-contributi-aggregato.xlsx' // imposta il nome del file
				link.click()
			}).catch(error => {
				this.errors.push({text: error, pre: true})
			}).finally(() => {
				this.loadingEsploso = false
			})
		},
		// genera una versione esplosa del report (occhio che è pesante!)
		generaReportEsploso() {
			this.reportEsploso = []
			this.loading = true
			this.$store.dispatch('genericApiPost', {apiUrl: '/report/caricaContributiEsplosi', value: this.reportRequest}).then(result => {
				this.reportEsploso = result
			}).catch(error => {
				this.errors.push({text: error, pre: true})
			}).finally(() => {
				this.loading = false
			})
		},
		// genera una versione esplosa del report in formato xlsx (occhio che è pesante!)
		generaReportEsplosoXlsx() {
			this.reportEsploso = []
			this.loadingEsploso = true
			this.$store.dispatch('genericApiPost', {apiUrl: '/report/caricaContributiEsplosiXlsx', value: this.reportRequest, options: {responseType: 'blob'}}).then(blob => {
				// crea il link farlocco per aprire il doc
				let link = document.createElement('a')
				document.body.appendChild(link) // fix per firefox
				link.setAttribute('type', 'hidden') // fix per firefox
				link.href = window.URL.createObjectURL(blob)
				link.target = '_blank'
				link.download = 'report-contributi-esploso.xlsx' // imposta il nome del file
				link.click()
			}).catch(error => {
				this.errors.push({text: error, pre: true})
			}).finally(() => {
				this.loadingEsploso = false
			})
		},
		// [OLD! non più in uso a favore della versione xlsx] genera una versione esplosa del report già in formato html pronta per l'output xls (occhio che è pesante!)
		/*
		generaReportEsplosoHtml() {
			this.reportEsploso = []
			this.loadingEsploso = true
			this.$store.dispatch('genericApiPost', {apiUrl: '/report/caricaContributiEsplosiHtml', value: this.reportRequest}).then(result => {
				let base64 = window.btoa(window.unescape(encodeURIComponent(result)))
				const bstr = atob(base64)
				let n = bstr.length
				const u8arr = new Uint8Array(n)
				while (n--) {
					u8arr[n] = bstr.charCodeAt(n)
				}
				// crea il blob con il giusto content type 
				let blob = new Blob([u8arr], { type: 'text/html' })
				// crea il link farlocco per aprire il doc
				let link = document.createElement('a')
				document.body.appendChild(link) // fix per firefox
				link.setAttribute('type', 'hidden') // fix per firefox
				link.href = window.URL.createObjectURL(blob)
				link.target = '_blank'
				link.download = 'report-contributi-esploso.xls' // imposta il nome del file
				link.click()
			}).catch(error => {
				this.errors.push({text: error, pre: true})
			}).finally(() => {
				this.loadingEsploso = false
			})
		},
		// usata per l'output del report esploso che, al contrario degli altri non usa json-excel in quanto viene preparato in xhtml già a livello server (è necessario per via della sua complessità)
		base64ToBlob(data, mime) {
			let base64 = window.btoa(window.unescape(encodeURIComponent(data)))
			let bstr = atob(base64)
			let n = bstr.length
			let u8arr = new Uint8ClampedArray(n)
			while (n--) {
				u8arr[n] = bstr.charCodeAt(n)
			}
			return new Blob([u8arr], { type: mime })
		}
		*/
	},
}
</script>

<style scoped lang="less">
	
</style>